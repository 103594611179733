import React, {useEffect, useState} from "react";
import { Identification } from "../../Identification";
import {getListeIdentificationSommaire} from "../../../../../data/rateau";
import {Divider} from "antd";
import {TraitementLettre} from "./EB3/TraitementLettre";
import {ObservationGenerale} from "./EB3/ObservationGenerale";
import {ListeModeleLettre} from "./EB3/ListeModeleLettre";
import swal from "sweetalert";
import {succesMessage} from "../../../../../helpers/AssetsHelpers";
import {ModalTitle} from "../../../../modals/modals.components";
import ModalAlert from "../../../../modals/Modal";
import ModalNavBarMenu from "../../../../modals/ModalNavBarMenu";
import {faEnvelopeOpenText, faFileArchive, faFileText, faIdCard} from "@fortawesome/free-solid-svg-icons";
import {IdentificationUser} from "../../IdentificationUser";
import UserCycleIdentification from "../../UserCycleIdentification";

const LettreMission = ({
       handleVisibility,
       mission,
       client,
       visible,
       codeSommaire,
       // setVisible
    }) => {
    const [activeTab, setActiveTab] = useState(0)
    const [loading, setLoading] = useState(false)
    const [date, setDate] = useState('');
    const [tache_id, setTache_id] = useState(null);
    const [intervention_id, setIntervention_id] = useState(null);
    const [user_id, setUser_id] = useState(null);
    // const isProvisoire = false;
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [heureEntree, setHeureEntree] = useState('');
    const [identifications, setIdentifications] = useState([]);
    const [observation, setObservation] = useState(null)
    const [modalId, setModalId] = useState(0)
    const [auth, setAuth] = useState(false);


    useEffect(()=> {
        if(activeTab === 0){
            getListeIdentificationSommaire(mission.id, codeSommaire)
                .then((res) => {
                    setIdentifications(res.result)
                })
        }
    }, [activeTab, codeSommaire, mission])

    const handleModalOpen = (value, modal) => {
        setLoading(true)
        setTimeout(() => {
            setIsModalOpen(prevState => !prevState);

            if(modal){
                setModalId(modal)
            }
            else{
                setTache_id(value.tache_id);
                setIntervention_id(value.intervention_id);
            }
            setLoading(false)
        }, 1500)
    };

    const handleSubmit = async (e) => {
        e.preventDefault()

        const data = {
            intervention_id,
            date,
            heureEntree,
            tache_id,
            user_id,
            codeSommaire,
            observation,
            exercice_id: mission.id,
            client_id: client.id
        };

        await swal(succesMessage, '', 'success')
        // const {status, message} = await updateReponseQuestion(data)
        // setMessage(message)
        // if (status === 'success') {
        //     setError(false)
        //     setLoading(false)
        //     setObservation(null)
        //     await swal(succesMessage, '', 'success')
        // } else {
        //     setError(true)
        //     setMessage(message)
        //     setLoading(false)
        // }
    }

    const steps = [
        {
            title: "IDENTIFICATIONS",
            icon: faIdCard,
        },
        {
            title: "MODELE LETTRE DE MISSION",
            icon: faFileArchive,
        },
        {
            title: "LETTRE DE MISSION",
            icon: faEnvelopeOpenText,
        },
        {
            title: "OBSERVATION",
            icon: faFileText,
        }
    ]

    return (
        <>
            <ModalAlert
                closeModal={handleVisibility}
                show={visible}
                title={<ModalTitle> {codeSommaire || ""} - Lettre de mission </ModalTitle>}
                footer={[]}
                modalStyles={{ top: 15 }}
                width={1400}
            >
                <Divider/>
                <ModalNavBarMenu
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        steps={steps}
                        setLoading={setLoading}
                        loading={loading}
                        auth={auth}
                    />
                {activeTab === 0 && (
                    <UserCycleIdentification
                    setDate={setDate}
                    dateDebut={date}
                    setTache_id={setTache_id}
                    setIntervention_id={setIntervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    mission={mission}
                    prev={null}
                    loading={loading}
                    codeSommaire={codeSommaire}
                    setIsAuthorized={setAuth}
                    isAuthorized={auth}
                    setActiveStep={setActiveTab}
                />
                )}
                {activeTab === 1 && (
                    <ListeModeleLettre
                        mission={mission}
                        client={client}
                        codeSommaire={codeSommaire}
                        intervention_id={intervention_id}
                        date={date}
                        heureEntree={heureEntree}
                        tache_id={tache_id}
                        user_id={user_id}
                    />
                )}
                {activeTab === 2 && (
                    <TraitementLettre
                        mission={mission}
                        client={client}
                        codeSommaire={codeSommaire}
                        intervention_id={intervention_id}
                        date={date}
                        heureEntree={heureEntree}
                        tache_id={tache_id}
                        user_id={user_id}
                    />
                )}
                {activeTab === 3 && (
                    <ObservationGenerale
                        observation={observation}
                        setObservation={setObservation}
                    />
                )}
            {/* {(isModalOpen && modalId === 1) && <IdentificationUser
                setDate={setDate}
                setTache_id={setTache_id}
                tache_id={tache_id}
                setIntervention_id={setIntervention_id}
                intervention_id={intervention_id}
                setUser_id={setUser_id}
                mission={mission}
                visible={isModalOpen}
                handleVisibility={handleVisibility}
                handleValider={handleModalOpen}
                setHeureEntree={setHeureEntree}
                loading={loading}
            />} */}
            </ModalAlert>
        </>
    );
}

export {LettreMission}