import {
    ClockCircleOutlined,
    UserOutlined
} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Input,
    notification,
    Popconfirm, Row,
    Select,
    Space,
    Table,
    Tag,
    Tooltip
} from "antd";
import {useSelector} from "react-redux";
import swal from "sweetalert";
import {optionsSwal, succesMessage} from "../../../helpers/AssetsHelpers";
import {ajouterRateau, getListeNextControleSommaire, validateRateau} from "../../../data/rateau";
import moment from "moment/moment";
import {getListeEquipeMission} from "../../../data/exercice";
import {areArraysEqual} from "../../utilities/Utilities";
import Swal from "sweetalert2";
import {appTheme} from "../../../config/theme";
import {getListeTacheActive} from "../../../data/params/tache";
import {getListeInterventionActive} from "../../../data/params/intervention";
import {getListeRole} from "../../../data/params/role";
import * as Yup from "yup";
import TableTitle from "../../common/TableTitle";
import {FormContainer, TagItem} from "../../common/common.components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCalendarDay,
    faCartPlus,
    faCheckCircle,
    faEdit,
    faIdCard,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import ModalAlert from "../../modals/Modal";
import {ModalTitle} from "../../modals/modals.components";
import ModalNavBarMenu from "../../modals/ModalNavBarMenu";
import SearchRow from "../../common/SearchRow";
import FormLabel from "../../common/Form/FormLabel";
import {Form, Formik} from "formik";
import FormError from "../../common/Form/FormError";
import ModalNavBarMenuRateau from "../../modals/ModalNavBarMenuRateau";

const NextControleSommaire = ({
          handleVisibility,
          visible,
          mission,
          codeSommaire,
          date,
          intervention_id,
          tache_id,
          client
      }) => {
    const user = useSelector(state => state.user)
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [selectItems, setSelectItems] = useState([]);
    const [selectData, setSelectData] = useState([]);
    const [heureEntree] = useState(moment().format('HH:mm:ss'))
    const [role, setRole] = useState(null);
    const [filtersItems, setFiltersItems] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [rateau, setRateau] = useState(null);
    const [taches, setTaches] = useState([]);
    const [interventions, setInterventions] = useState([]);
    const [roles, setRoles] = useState([]);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 5,
        },
    });

    const [paramsRecherche, setParamsRecherche] = useState({
        infosUser: "",
        tache_id: null,
        intervention_id: null,
        role_id: null,
        date_debut: "",
        date_fin: "",
    });

    useEffect(() => {
        if(activeTab === 0){
            fetchRateaux(mission.id, codeSommaire)
        }
    }, [codeSommaire, mission, activeTab])

    useEffect(() => {
        if (mission) {
            fetchUserMission(mission.id);
        }
    }, [mission, selectData]);

    const fetchRateaux = (exercice_id, codeSommaire) => {
        getListeNextControleSommaire(exercice_id, codeSommaire)
            .then((res) => {
                if(res.result && res.result.length > 0){
                    const resFiltered = res.result.sort((p1, p2) => {
                        return new Date(p2.createdAt) - new Date(p1.createdAt);
                    })
                    setSelectData(resFiltered);
                    setFiltersItems(resFiltered);

                    let updatedMap = [];
                    resFiltered.map((item) => {
                        item.Lea_RateauDetails?.map((detail) => {
                            const element = {
                                id: detail.id,
                                refFT: detail?.refFT,
                                pointSus: detail?.pointSus,
                                observation: detail?.observation,
                                initial: item.Lea_User?.initial ? item.Lea_User?.initial: (item.Lea_User?.nom_user + ' ' + item.Lea_User?.prenom_user),
                                codeSommaire: item.codeSommaire,
                                exercice: item.Lea_ExercieAuditClient,
                                client: item.Lea_Client,
                                user: item.Lea_User,
                                createdAt: item.createdAt,
                                valideUserId: detail.valideUserId,
                                valideUser: detail.valideUser,
                                dateValidate: detail.dateValidate,
                                isValidate: detail.isValidate,
                                isUser: item.Lea_User?.id === user.id,
                                isCreated: false
                            }

                            updatedMap.push(element)
                        })
                    })

                    setSelectItems(updatedMap)
                }
            });
    };

    const fetchUserMission = (id) => {
        getListeEquipeMission(id)
            .then((res) => {
                const opt = res.find((item) => (item.exercice_id === mission?.id &&  item.membre_id === user.id));
                setRole(opt?.Lea_RoleMission);

                const updatedIdentifications = selectData.map((item) => {
                    const user = res.find((key) => key.membre_id === item.Lea_User?.id);
                    return user ? { ...item, role: user.Lea_RoleMission } : item;
                });
                if (res.length > 0 && !areArraysEqual(updatedIdentifications, selectData)) {
                    setSelectData(updatedIdentifications);
                    setFiltersItems(updatedIdentifications);
                }
                // setSelectData(res.length > 0 ? updatedIdentifications : selectData);
            })
            .catch((error) => {
                console.error("Erreur", error);
            });
    };
    useEffect(() => {
        const fetchListeTache = () => {
            getListeTacheActive().then((res) => {
                setTaches(res?.result);
            });
        };

        const fetchIntervention = () => {
            getListeInterventionActive().then((res) => {
                setInterventions(res?.result);
            });
        };

        const fetchRole = () => {
            getListeRole().then((res) => {
                setRoles(res);
            });
        };

        fetchRole();
        fetchListeTache();
        fetchIntervention();
    }, []);

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // if (pagination.pageSize !== tableParams.pagination?.pageSize) {
        //     setSelectData([]);
        // }
    };

    const handleMopenOpen = (data) => {
        setRateau(data);

        setIsModalOpen(prevState => !prevState);
    }

    const handleDeleteElement = (index) => {
        const updatedList = selectItems.filter((_, i) => i !== index);
        setSelectItems(updatedList);
    };

    const handleValidateRateau = async (detail_id, valideUserId, isValidate) => {
        Swal.fire(optionsSwal).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true)
                const data = {detail_id, valideUserId, isValidate}
                const {status, message} = await validateRateau(data)
                if (status === 'error') {
                    await swal(`${message}`, '', 'danger')
                }
                if (status === 'success') {
                    fetchRateaux(mission.id, codeSommaire)
                    setLoading(false)
                    await swal(succesMessage, '', 'success')
                } else {
                    setLoading(false)
                }
            }
        })
    }

    const handeSubmit = async (value) => {
        setLoading(true);

        const valuesData = [{
            refFT: value.refFT,
            pointSus: value.pointSus,
            observation: value.observation,
            sigle: client ? client.sigle : "",
            denomination: client ? client.denomination : "",
            isUser: true,
            isCreated: true
        }]

        const data = {
            heureEntree,
            tache_id,
            user_id: user.id,
            codeSommaire,
            exercice_id: mission.id,
            userAdd_id: user.id,
            isNextControl: false,
            intervention_id,
            date: date,
            client_id: mission.client_id,
            rateauxData: valuesData
        }

        const {status, message} = await ajouterRateau(data)
        if (status === 'success') {
            setLoading(false)
            notification.success({
                description: succesMessage,
            });
            fetchRateaux(mission.id, codeSommaire);
            handleMopenOpen();
        } else {
            notification.error({
                description: message,
            });
            setLoading(false)
        }
    }

    const validationScheme = Yup.object({
        refFT: Yup.string().required("La référence obligatoire"),
        pointSus: Yup.string().required("Le contenu est obligatoire"),
    });


    const columnsIdentification = [
        {
            title: () => <TableTitle>Nom et Prénoms / Initial</TableTitle>,
            dataIndex: 'name',
            width: 150,
            render: (_, record) => (
                <>
                    <span style={{ color: "#000", fontWeight: "bold" }}>
                        {record.Lea_User?.initial ? record.Lea_User?.initial : (record.Lea_User?.nom_user + ' ' + record.Lea_User?.prenom_user)}
                    </span>
                    <br />
                    {record.Lea_User?.initial && <Tag prefix="@" color={appTheme.colors.primary_blue}>{record.Lea_User?.initial}</Tag>}
                </>
            ),
        },
        {
            title: () => <TableTitle>Titre</TableTitle>,
            dataIndex: 'titre',
            width: 150,
            render: (_, record) => (
                <TagItem color={appTheme.colors.primary_blue}>{record?.role?.libelle}</TagItem>
            ),
        },
        {
            title: () => <TableTitle>Interventions</TableTitle>,
            width: 200,
            dataIndex: 'interventionType',
            render: (_, record) => (
                <TagItem>{record.Bog_Intervention?.libelle}</TagItem>
            ),
        },
        {
            title: () => <TableTitle>Date</TableTitle>,
            dataIndex: 'date',
            width: 150,
            render: (_, record) => (
                <span className='chart-blue display-block f-size-12' style={{fontWeight: 600}}>
                    <ClockCircleOutlined style={{fontSize: '15px'}} />{' '}
                    {moment(record.dateAdd).format('DD/MM/YYYY à HH:mm')}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Heure d'entrée</TableTitle>,
            dataIndex: 'heureEntree',
            width: 100,
            render: (_, record) => (
                <span className='fw-bolder fs-7' >
                    {record?.heureEntree}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Heure de sortie</TableTitle>,
            dataIndex: 'heureSortie',
            width: 100,
            render: (_, record) => (
                <span className='fw-bolder fs-7' >
                    {(record?.heureSortie)}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Tâches</TableTitle>,
            dataIndex: 'intervention',
            width: 200,
            render: (_, record) => (
                <TagItem>{record.Bog_TacheAutorise?.libelle}</TagItem>
            )
        },
        {
            title: () => <TableTitle>Reference</TableTitle>,
            dataIndex: 'reference',
            width: 100,
            render: (_, record) => (
                record.codeSommaire && <Tag color={appTheme.colors.primary}>{record.codeSommaire}</Tag>
            ),
        },
    ];

    const columnsTraitement = [
        {
            title: () => <TableTitle>REF FT</TableTitle>,
            dataIndex: 'refFT',
            width: 100,
            render: (_, record) => (
                <Tag color={appTheme.colors.primary}>{record.refFT}</Tag>
            ),
        },
        {
            title: () => <TableTitle>Points en suspens</TableTitle>,
            dataIndex: 'pointSus',
            render: (_, record) => (
                <>
                    <Input.TextArea
                        style={{width: "100%"}}
                        bordered={false}
                        autoSize={{ minRows: 1, maxRows: 6 }}
                        value={record.pointSus}
                    />
                </>
            ),
        },
        {
            title: () => <TableTitle>Observation</TableTitle>,
            dataIndex: 'observation',
            render: (_, record) => (
                <>
                    <Input.TextArea
                        style={{width: "100%"}}
                        bordered={false}
                        autoSize={{ minRows: 1, maxRows: 6 }}
                        value={record.observation}
                    />
                </>
            ),
        },
        {
            title: () => <TableTitle>Etat</TableTitle>,
            dataIndex: 'ajout',
            render: (_, record) => (
                <div className='d-flex flex-column align-center'>
                    <div className="mb-2">
                        {record.isValidate ?
                            <Tag color={"green"}>Terminé</Tag>
                            :
                            <Tag color={"yellow"}>En Attente</Tag>
                        }
                    </div>
                    <div className="d-flex flex-column">
                        {record.valideUser && <span className='chart-orange display-block f-size-12 pe-5'
                                                    style={{fontWeight: 600, fontSize: '10px'}}>
                        <UserOutlined style={{fontSize: '12px'}}/> {record.valideUser}
                        </span>}
                        {record.dateValidate && <span className='chart-blue display-block f-size-12' style={{fontWeight: 600}}>
                        <ClockCircleOutlined style={{fontSize: '10px'}}/>{' '}
                            {moment(record.dateValidate).format('DD/MM/YYYY à HH:mm')}
                    </span>}
                    </div>
                </div>
            ),
        },
        {
            title: () => <TableTitle>Mission</TableTitle>,
            dataIndex: 'reference',
            render: () => (
                <div className='d-flex flex-column'>
                    <span style={{ color: "#000", fontWeight: "bold", fontSize: "12px" }}>
                        {mission.denomination}
                    </span>
                    <span className='text-gray-800 fw-bold text-hover-primary mb-1' style={{fontSize: '12px'}}>
                        {mission.mission} <span className="ms-3">{mission.annee && <TagItem color={appTheme.colors.primary}>{mission.annee}</TagItem>}</span>
                    </span>
                </div>
            ),
        },
        {
            title: () => <TableTitle>Ajout</TableTitle>,
            dataIndex: 'ajout',
            render: (_, record) => (
                record.user && <div className='d-flex flex-column align-center'>
                    <span className='chart-orange display-block f-size-12 pe-5' style={{fontWeight: 600, fontSize: '11px'}}>
                        <UserOutlined style={{fontSize: '15px'}} /> {record.user?.initial ? record.user?.initial : (record.user?.nom_user + ' ' + record.user?.prenom_user)}
                    </span>
                    <span className='chart-blue display-block f-size-12' style={{fontWeight: 600}}>
                        <ClockCircleOutlined style={{fontSize: '12px'}} />{' '}
                        {moment(record.createdAt).format('DD/MM/YYYY à HH:mm')}
                    </span>
                </div>
            ),
        },
        {
            title: () => <TableTitle>Action</TableTitle>,
            dataIndex: 'action',
            width: 100,
            render: (_, record, index) => (
                <>
                    <Space>
                        {(mission.isCloture !== 1 || mission.isClotureProvisoire !==1) &&
                            <>
                                {!record.isValidate && <Tooltip color={appTheme.colors.primary} title="Confirmer son achèvement">
                                    <Button
                                        shape="circle"
                                        size={"middle"}
                                        style={{backgroundColor: appTheme.colors.primary}}
                                        onClick={() => handleValidateRateau(record.id, user.id, true)}
                                        icon={<FontAwesomeIcon icon={faCheckCircle} color={"white"}/>}
                                    />
                                </Tooltip>}
                                {(!record.isValidate && record.isUser) && <Tooltip color='blue' title="Modifier les infos du fichier">
                                    <Button
                                        shape="circle"
                                        size={"middle"}
                                        style={{backgroundColor: "blue"}}
                                        onClick={() => handleMopenOpen(record)}
                                        icon={<FontAwesomeIcon icon={faEdit} color={"white"}/>}
                                    />
                                </Tooltip>}
                                {(!record.isValidate && record.isUser) && <Tooltip title="Supprimer le râteau">
                                    <Popconfirm
                                        title="Confirmer la suppression de ce râteau"
                                        description="Voulez-vous vraiment suprimer ce râteau?"
                                        onClick={() => handleDeleteElement(index)}
                                    >
                                        <Button
                                            shape={"circle"}
                                            size={"middle"}
                                            icon={<FontAwesomeIcon color="red" icon={faTrash} />}
                                            style={{color: "red"}}
                                        />
                                    </Popconfirm>
                                </Tooltip>}
                            </>
                        }
                    </Space>
                </>
            ),
        },
    ];

    const handleSearch = () => {
        setLoading(true);

        setTimeout(() => {
            const filteredClients = selectData.filter(ident =>
                (ident.nom_user?.toLowerCase()?.includes(paramsRecherche.infosClient?.toLowerCase()) ||
                    ident.initial?.toLowerCase()?.includes(paramsRecherche.infosClient?.toLowerCase()) ||
                    ident.Lea_User?.nom_user?.toLowerCase()?.includes(paramsRecherche.infosClient?.toLowerCase()) ||
                    ident.Lea_User?.prenom_user?.toLowerCase()?.includes(paramsRecherche.infosClient?.toLowerCase())) &&
                (paramsRecherche.role_id === "" || ident.role_id === paramsRecherche.role_id) &&
                (paramsRecherche.tache_id === "" || ident.tache_id === paramsRecherche.tache_id) &&
                (paramsRecherche.intervention_id === "" || ident.intervention_id === paramsRecherche.intervention_id)
            );
            setFiltersItems(filteredClients)
            setLoading(false);
        }, 2000);
    };

    const handleReset = () => {
        setLoading(true)
        setParamsRecherche({
            infosUser: "",
            tache_id: null,
            intervention_id: null,
            role_id: null,
            date_debut: "",
            date_fin: "",
        });
        setFiltersItems(selectData)
        setLoading(false);
    };

    const steps = [
        {
            title: "IDENTIFICATIONS",
            icon: faIdCard,
        },
        {
            title: "PROCHAIN CONTRÔLE",
            icon: faCalendarDay,
        }
    ]


    return (
        <>
            <ModalAlert
                closeModal={handleVisibility}
                show={visible}
                title={<ModalTitle> Identification {codeSommaire ? `de ${codeSommaire || ""}` : ""}</ModalTitle>}
                footer={[]}
                modalStyles={{ top: 15 }}
                width={1400}
            >
                <Divider/>
                <ModalNavBarMenuRateau setActiveTab={setActiveTab} activeTab={activeTab} steps={steps} setLoading={setLoading}/>
                {activeTab === 0 &&
                    (
                        <>
                            <SearchRow
                                handleSearch={handleSearch}
                                handleReset={handleReset}
                                loading={loading}
                            >
                                <Col
                                    xs={{ span: 24 }}
                                    md={{ span: 12 }}
                                    lg={{ span: 4 }}
                                    xl={{ span: 4 }}
                                >
                                    <FormLabel label="Utilisateur" />
                                    <Input
                                        style={{ width: "100%" }}
                                        size="large"
                                        placeholder="Nom, prenom, initial, téléphone, email"
                                        onChange={(e) =>
                                            setParamsRecherche({
                                                ...paramsRecherche,
                                                infosUser: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col
                                    xs={{ span: 24 }}
                                    md={{ span: 12 }}
                                    lg={{ span: 4 }}
                                    xl={{ span: 4 }}
                                >
                                    <FormLabel label="Roles" />
                                    <Select
                                        showSearch
                                        placeholder="Sélectionnez le rôle..."
                                        optionFilterProp="label"
                                        filterOption={(input, option) =>
                                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        options={roles.map((role) => ({
                                            value: role.id,
                                            label: role.libelle,
                                        }))}

                                        onSelect={(value) =>
                                            setParamsRecherche({ ...paramsRecherche, role_id: value })
                                        }
                                        size="large"
                                        style={{ width: "100%" }}
                                    />
                                </Col>
                                <Col
                                    xs={{ span: 24 }}
                                    md={{ span: 12 }}
                                    lg={{ span: 4 }}
                                    xl={{ span: 4 }}
                                >
                                    <FormLabel label="Tâches" />
                                    <Select
                                        showSearch
                                        placeholder="Sélectionnez la tâche..."
                                        optionFilterProp="label"
                                        filterOption={(input, option) =>
                                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        options={taches.map((tache) => ({
                                            value: tache.id,
                                            label: tache.libelle,
                                        }))}

                                        onSelect={(value) =>
                                            setParamsRecherche({ ...paramsRecherche, tache_id: value })
                                        }
                                        size="large"
                                        style={{ width: "100%" }}
                                    />
                                </Col>
                                <Col
                                    xs={{ span: 24 }}
                                    md={{ span: 12 }}
                                    lg={{ span: 4 }}
                                    xl={{ span: 4 }}
                                >
                                    <FormLabel label="Interventions" />
                                    <Select
                                        showSearch
                                        placeholder="Sélectionnez l'intervation..."
                                        optionFilterProp="label"
                                        filterOption={(input, option) =>
                                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        options={interventions.map((tache) => ({
                                            value: tache.id,
                                            label: tache.libelle,
                                        }))}
                                        onSelect={(value) =>
                                            setParamsRecherche({ ...paramsRecherche, intervention_id: value })
                                        }
                                        size="large"
                                        style={{ width: "100%"}}
                                    />
                                </Col>
                                <Col
                                    xs={{ span: 24 }}
                                    md={{ span: 12 }}
                                    lg={{ span: 4 }}
                                    xl={{ span: 4 }}
                                >
                                    <FormLabel label="Date début" />
                                    <DatePicker
                                        size="large"
                                        placeholder="Date début"
                                        style={{ width: "100%" }}
                                        allowClear
                                        onChange={(date, dateString) =>
                                            date !== null
                                                ? setParamsRecherche({
                                                    ...paramsRecherche,
                                                    date_debut: date.format("YYYY-MM-DD"),
                                                })
                                                : setParamsRecherche({ ...paramsRecherche, date_debut: "" })
                                        }
                                        format={["DD-MM-YYYY", "YYYY-MM-DD"]}
                                    />
                                </Col>
                                <Col
                                    xs={{ span: 24 }}
                                    md={{ span: 12 }}
                                    lg={{ span: 4 }}
                                    xl={{ span: 4 }}
                                >
                                    <FormLabel label="Date fin" />
                                    <DatePicker
                                        size="large"
                                        placeholder="Date fin"
                                        style={{ width: "100%" }}
                                        allowClear
                                        onChange={(date, dateString) =>
                                            date !== null
                                                ? setParamsRecherche({
                                                    ...paramsRecherche,
                                                    date_fin: date.format("YYYY-MM-DD"),
                                                })
                                                : setParamsRecherche({ ...paramsRecherche, date_fin: "" })
                                        }
                                        format={["DD-MM-YYYY", "YYYY-MM-DD"]}
                                    />
                                </Col>
                            </SearchRow>
                            <FormContainer>
                                <div className="w-100">
                                    <div className="py-4">
                                        <div className="table-responsive">
                                            <Table
                                                columns={columnsIdentification}
                                                rowKey={(record) => record.id}
                                                dataSource={filtersItems}
                                                pagination={tableParams.pagination}
                                                loading={loading}
                                                onChange={handleTableChange}
                                                size={"small"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </FormContainer>
                        </>
                    )
                }
                {activeTab === 1 &&
                    (
                        <>
                            <SearchRow
                                handleSearch={handleSearch}
                                handleReset={handleReset}
                                loading={loading}
                            >
                                <Col
                                    xs={{ span: 24 }}
                                    md={{ span: 12 }}
                                    lg={{ span: 6 }}
                                    xl={{ span: 6 }}
                                >
                                    <FormLabel label="Référence" />
                                    <Input
                                        style={{ width: "100%" }}
                                        size="large"
                                        placeholder="Référence"
                                        onChange={(e) =>
                                            setParamsRecherche({
                                                ...paramsRecherche,
                                                infosUser: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col
                                    xs={{ span: 24 }}
                                    md={{ span: 12 }}
                                    lg={{ span: 6 }}
                                    xl={{ span: 6 }}
                                >
                                    <FormLabel label="Etat" />
                                    <Select
                                        showSearch
                                        placeholder="Sélectionnez l'état de traitement..."
                                        optionFilterProp="label"
                                        filterOption={(input, option) =>
                                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        options={[
                                            {label: "En attente", value: false},
                                            {label: "Terminé", value: true},
                                        ]}
                                        onSelect={(value) =>
                                            setParamsRecherche({ ...paramsRecherche, intervention_id: value })
                                        }
                                        size="large"
                                        style={{ width: "100%"}}
                                    />
                                </Col>
                                <Col
                                    xs={{ span: 24 }}
                                    md={{ span: 12 }}
                                    lg={{ span: 6 }}
                                    xl={{ span: 6 }}
                                >
                                    <FormLabel label="Date début" />
                                    <DatePicker
                                        size="large"
                                        placeholder="Date début"
                                        style={{ width: "100%" }}
                                        allowClear
                                        onChange={(date, dateString) =>
                                            date !== null
                                                ? setParamsRecherche({
                                                    ...paramsRecherche,
                                                    date_debut: date.format("YYYY-MM-DD"),
                                                })
                                                : setParamsRecherche({ ...paramsRecherche, date_debut: "" })
                                        }
                                        format={["DD-MM-YYYY", "YYYY-MM-DD"]}
                                    />
                                </Col>
                                <Col
                                    xs={{ span: 24 }}
                                    md={{ span: 12 }}
                                    lg={{ span: 6 }}
                                    xl={{ span: 6 }}
                                >
                                    <FormLabel label="Date fin" />
                                    <DatePicker
                                        size="large"
                                        placeholder="Date fin"
                                        style={{ width: "100%" }}
                                        allowClear
                                        onChange={(date, dateString) =>
                                            date !== null
                                                ? setParamsRecherche({
                                                    ...paramsRecherche,
                                                    date_fin: date.format("YYYY-MM-DD"),
                                                })
                                                : setParamsRecherche({ ...paramsRecherche, date_fin: "" })
                                        }
                                        format={["DD-MM-YYYY", "YYYY-MM-DD"]}
                                    />
                                </Col>
                            </SearchRow>
                            <FormContainer>
                                <div className="d-flex justify-content-end align-center my-2 me-5">
                                    {(mission.isCloture !== 1 || mission.isClotureProvisoire !==1) && <Tooltip color={appTheme.colors.primary} title="Ajouter un nouveau râteau">
                                        <Button
                                            size={"middle"}
                                            style={{color: "white", backgroundColor: appTheme.colors.primary}}
                                            onClick={handleMopenOpen}
                                            icon={<FontAwesomeIcon icon={faCartPlus}/>}
                                        >
                                            Ajouter
                                        </Button>
                                    </Tooltip>}
                                </div>
                                <div className="w-100">
                                    <div className="py-4">
                                        <div className="table-responsive">
                                            <Table
                                                columns={columnsTraitement}
                                                rowKey={(record) => record.id}
                                                dataSource={selectItems}
                                                pagination={tableParams.pagination}
                                                loading={loading}
                                                onChange={handleTableChange}
                                                size={"small"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </FormContainer>
                        </>
                    )
                }
            </ModalAlert>
            <ModalAlert
                closeModal={handleMopenOpen}
                show={isModalOpen}
                title={<ModalTitle>Prochain contrôle</ModalTitle>}
                footer={[]}
                modalStyles={{ top: 30 }}
                width={600}
            >
                <Formik
                    initialValues={{
                        refFT: rateau?.refFT || "",
                        pointSus: rateau?.pointSus || "",
                        observation: rateau?.observation || "",
                        rateau_id: rateau?.id || null,
                    }}
                    onSubmit={(values) => handeSubmit(values)}
                    validationSchema={validationScheme}
                >
                    {({ values, handleSubmit, setFieldValue, touched, errors }) => (
                        <Form>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <FormLabel label="Référence" />
                                    <Input
                                        size="large"
                                        placeholder="Saisir la reférence"
                                        name="refFT"
                                        value={values.refFT}
                                        onChange={(e) =>
                                            setFieldValue("refFT", e.target.value)
                                        }
                                    />
                                    {touched.refFT && errors.refFT && (
                                        <FormError fieldName="refFT" />
                                    )}
                                </Col>
                                <Col span={24}>
                                    <FormLabel label="Point en suspens" />
                                    <Input.TextArea
                                        size="large"
                                        placeholder="Saisissez le contenu"
                                        name="pointSus"
                                        value={values.pointSus}
                                        onChange={(e) =>
                                            setFieldValue("pointSus", e.target.value)
                                        }
                                        showCount
                                        rows={4}
                                    />
                                    {touched.pointSus && errors.pointSus && (
                                        <FormError fieldName="pointSus" />
                                    )}
                                </Col>
                                <Col span={24}>
                                    <FormLabel label="Observation" />
                                    <Input.TextArea
                                        size="large"
                                        placeholder="Saisissez l'observation"
                                        name="observation"
                                        value={values.observation}
                                        onChange={(e) =>
                                            setFieldValue("observation", e.target.value)
                                        }
                                        showCount
                                        rows={4}
                                    />
                                    {touched.observation && errors.observation && (
                                        <FormError fieldName="observation" />
                                    )}
                                </Col>
                                <Col span={24}>
                                    <Button
                                        type="primary"
                                        block
                                        size="large"
                                        loading={loading}
                                        disabled={loading}
                                        onClick={handleSubmit}
                                    >
                                        Valider
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </ModalAlert>
        </>
    )
}

export {NextControleSommaire}